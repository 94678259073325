.uv-label-0 {
  font-weight: 700;
  font-size: 16px;
  color: #404040;
}
.uv-label-1 {
  font-weight: 400;
  color: var(--U-colors-Blue);
  text-decoration: none;
  &:hover {
    color: var(--U-colors-Blue);
    text-decoration: underline;
  }
}
.uv-label-2 {
  color: #b8b8b8;
  cursor: pointer;
}
.uv-label-7 {
  font-weight: 700;
  color: #fff;
}
.uv-label-8 {
  font-weight: 400;
  color: #BAB9BA;
}
.uv-label-9 {
  font-weight: 400;
  font-size: 12px;
  color: #7A7A7A;
}