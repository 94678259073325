@use "@angular/material" as mat;
@include mat.core();

$blue-palette: (
  50: var(--U-colors-Blue),
  100: var(--U-colors-Blue),
  200: var(--U-colors-Blue),
  300: var(--U-colors-Blue),
  400: var(--U-colors-Blue),
  500: var(--U-colors-Blue),
  600: var(--U-colors-Blue),
  700: var(--U-colors-Blue),
  800: var(--U-colors-Blue),
  900: var(--U-colors-Blue),
  A100: var(--U-colors-Blue),
  A200: var(--U-colors-Blue),
  A400: var(--U-colors-Blue),
  A700: var(--U-colors-Blue),
  contrast: (
    50: unset,
    100: unset,
    200: unset,
    300: unset,
    400: unset,
    500: unset,
    600: unset,
    700: unset,
    800: unset,
    900: unset,
    A100: unset,
    A200: unset,
    A400: unset,
    A700: unset,
  ),
);
$green-palette: (
  50: #a8cf16,
  100: #a8cf16,
  200: #a8cf16,
  300: #a8cf16,
  400: #a8cf16,
  500: #a8cf16,
  600: #a8cf16,
  700: #a8cf16,
  800: #a8cf16,
  900: #a8cf16,
  A100: #a8cf16,
  A200: #a8cf16,
  A400: #a8cf16,
  A700: #a8cf16,
  contrast: (
    50: unset,
    100: unset,
    200: unset,
    300: unset,
    400: unset,
    500: unset,
    600: unset,
    700: unset,
    800: unset,
    900: unset,
    A100: unset,
    A200: unset,
    A400: unset,
    A700: unset,
  ),
);

$custom-primary-palette: (
  50: var(--customizable-primary-color),
  100: var(--customizable-primary-color),
  200: var(--customizable-primary-color),
  300: var(--customizable-primary-color),
  400: var(--customizable-primary-color),
  500: var(--customizable-primary-color),
  600: var(--customizable-primary-color),
  700: var(--customizable-primary-color),
  800: var(--customizable-primary-color),
  900: var(--customizable-primary-color),
  A100: var(--customizable-primary-color),
  A200: var(--customizable-primary-color),
  A400: var(--customizable-primary-color),
  A700: var(--customizable-primary-color),
  contrast: (
    50: unset,
    100: unset,
    200: unset,
    300: unset,
    400: unset,
    500: unset,
    600: unset,
    700: unset,
    800: unset,
    900: unset,
    A100: unset,
    A200: unset,
    A400: unset,
    A700: unset,
  ),
);
$custom-green-palette: (
  50: var(--customizable-success-color),
  100: var(--customizable-success-color),
  200: var(--customizable-success-color),
  300: var(--customizable-success-color),
  400: var(--customizable-success-color),
  500: var(--customizable-success-color),
  600: var(--customizable-success-color),
  700: var(--customizable-success-color),
  800: var(--customizable-success-color),
  900: var(--customizable-success-color),
  A100: var(--customizable-success-color),
  A200: var(--customizable-success-color),
  A400: var(--customizable-success-color),
  A700: var(--customizable-success-color),
  contrast: (
    50: unset,
    100: unset,
    200: unset,
    300: unset,
    400: unset,
    500: unset,
    600: unset,
    700: unset,
    800: unset,
    900: unset,
    A100: unset,
    A200: unset,
    A400: unset,
    A700: unset,
  ),
);

$my-primary: mat.define-palette($blue-palette);
$my-accent: mat.define-palette($green-palette);
$my-theme: mat.define-light-theme(
  (
    color: (
      primary: $my-primary,
      accent: $my-accent,
    ),
  )
);
@include mat.all-component-themes($my-theme);

.mat-mdc-form-field {
  width: 100%;
}
.mat-mdc-form-field-hint-wrapper,
.mat-mdc-form-field-error-wrapper,
.mdc-text-field {
  padding: 0 !important;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled),
.mat-mdc-form-field-focus-overlay,
.mdc-text-field--disabled.mdc-text-field--filled {
  background-color: unset !important;
}
.mat-mdc-checkbox.mat-primary,
.mat-mdc-checkbox.mat-accent {
  --mdc-checkbox-selected-checkmark-color: #ffffff !important;
  --mdc-checkbox-unselected-focus-icon-color: #ffffff !important;
  --mdc-checkbox-unselected-hover-icon-color: #ffffff !important;
}

$custom-primary: mat.define-palette($custom-primary-palette);
$custom-accent: mat.define-palette($custom-green-palette);
$custom-theme: mat.define-light-theme(
  (
    color: (
      primary: $custom-primary,
      accent: $custom-primary,
    ),
  )
);
