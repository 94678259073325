.facebook-icon {
  width: 24px;
  height: 24px;
  background: url('~src/assets/img/facebook.png');
}
.youtube-icon {
  width: 24px;
  height: 24px;
  background: url('~src/assets/img/youtube.png');
}
.instagram-icon {
  width: 24px;
  height: 24px;
  background: url('~src/assets/img/instagram.png');
}
.twitter-icon {
  width: 24px;
  height: 24px;
  background: url('~src/assets/img/twitter.png');
}