@use "@angular/material" as mat;

@import "assets/styles/material";
@import "~bootstrap/dist/css/bootstrap.css";
@import "ngx-toastr/toastr";
@import "assets/styles/buttons";
@import "assets/styles/label";
@import "assets/styles/icons";

@import "~@ng-select/ng-select/themes/default.theme.css";
//@import "~@ng-select/ng-select/themes/material.theme.css";
@include mat.core();

:root {
  --U-colors-Blue: #01a1da;

  --customizable-primary-color: #0e2a8d;
  --customizable-success-color: #3e8f3e;
  --customizable-error-color: #d10d14;

  --customizable-border-radius: 0px;

  --customizable-second-color: #0d6efd;

  --customizable-success-secondary-color: #5cb85c;
}

html {
  height: 100%;
  min-height: 100%;
  font-size: 16px;
  overflow-x: visible;
  body {
    padding: 0;
    margin: 0;
    background-color: #f2f5f6;
    font-family: "Roboto", sans-serif;
    //font-family: 'SF Pro Display', sans-serif;
    color: #404040;
  }
}

ul {
  list-style-type: none;
  padding-inline-start: 0;
}

.w-100 {
  width: 100% !important;
}
.w-75 {
  width: 75% !important;
}
.w-50 {
  width: 50% !important;
}
.w-25 {
  width: 25% !important;
}
label {
  margin-bottom: 0 !important;
}
.uv-line {
  border-bottom: 1px solid #efefef;
}
.uv-line-1 {
  border-bottom: 1px solid #dedede;
}
a:hover {
  color: var(--U-colors-Blue);
  text-decoration: underline;
}

.ck-editor__editable_inline {
  height: 310px;
}

.special-text {
  border-radius: 6px;
  color: #008bc0;
  padding: 2px 4px;
  background-color: #e6f5fa;
}
.hint {
  background-color: #e6f5fa;
}

.gradient1 {
  background: linear-gradient(
    103deg,
    var(--U-colors-Blue) 21.57%,
    #cdd620 33.59%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.switch1 {
  width: 36px;
  height: 20px;
  flex-shrink: 0; /* Prevents shrinking */
}

.less {
  font-weight: 600;
}

.custom-component-theme {
  // @include mat.button-color($custom-theme);
  // @include mat.card-color($custom-theme);
  // Add other component mixins as needed

  @include mat.all-component-themes($custom-theme);
}

.c-link {
  color: var(--customizable-primary-color) !important;
}

.custom-component-theme {
  .mat-mdc-form-field {
    height: 42px !important;
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .mat-mdc-form-field-infix {
    min-height: 42px !important;
    max-height: 42px !important;
    padding-top: 4px !important; // Adjust this to align text correctly
    padding-bottom: 4px !important;
    display: flex;
    align-items: center; // Ensures input stays centered
  }

  .mat-mdc-text-field-wrapper {
    min-height: 42px !important;
    max-height: 42px !important;
  }

  .mat-mdc-form-field.mat-form-field-should-float .mat-mdc-floating-label {
    // top: 18px !important; // Adjust label position for better alignment
    font-size: 11px !important;
  }

  .mat-mdc-floating-label {
    top: 20px !important;
    font-size: 16px !important;
    padding-bottom: 2px;
    transition: font-size 0.2s ease-in-out;
  }

  .mat-mdc-form-field input.mat-mdc-input-element {
    color: var(--U-colors-Input---text-and-stroke, #7a7a7a) !important;
    font-family: Roboto !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 100% !important; /* 16px */
  }

  .mat-mdc-select {
    height: 42px !important;
    line-height: 42px !important;
  }

  .mat-mdc-form-field-required-marker {
    display: none !important;
  }

  .mat-mdc-select-arrow {
    display: none !important;
  }

  .mat-mdc-select-trigger {
    height: 42px !important;
    display: flex;
    align-items: center;
  }

  .mat-mdc-select.mat-mdc-select-panel-opened {
    transform: translateY(-50%) rotate(180deg);
  }

  .custom-chevron {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 20px;
    transition: transform 0.2s ease-in-out;
  }

  .custom-chevron {
    transition: transform 0.1s ease-in-out;
  }

  .rotate {
    transform: translateY(-60%) rotate(180deg);
  }

  .mat-mdc-radio-button {
    // Remove ripple/halo effects
    --mdc-radio-state-layer-color: transparent;
    --mdc-radio-focus-indicator-color: transparent;
    --mat-mdc-radio-ripple-color: transparent;
    --mat-mdc-radio-checked-ripple-color: transparent;

    padding: 0px !important;

    // Remove hover/pressed states
    --mdc-radio-unselected-hover-icon-color: rgba(0, 0, 0, 0);
    --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0);

    --mat-mdc-radio-checked-ripple-color: transparent;
    --mdc-radio-selected-focus-icon-color: transparent;
    --mdc-radio-selected-hover-icon-color: transparent;
    --mdc-radio-selected-icon-color: transparent;
    --mdc-radio-selected-pressed-icon-color: transparent;

    .mdc-radio {
      // Remove animation elements
      &::before,
      &::after {
        display: none !important;
      }

      .mdc-radio__background::before {
        display: none !important;
      }
    }
  }

  .mat-mdc-option .mat-mdc-option-selected {
    background-color: white !important; // Change to your desired color
    color: var(--customizable-primary-color) !important; // Change text color
  }
}

.custom-panel {
  box-shadow: none !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: var(--customizable-border-radius) !important;
}

.custom-panel {
  .mdc-list-item--selected span.mdc-list-item__primary-text {
    color: var(--customizable-primary-color) !important;
  }
}

.custom-panel .mat-pseudo-checkbox-checked::after {
  display: none !important;
}

.custom-component-theme .mat-mdc-form-field-error {
  font-size: 12px;
}

.custom-calendar {
  /* Selected date in CURRENT month */
  .mat-calendar-body-selected .mat-calendar-body-cell-content {
    background-color: var(--customizable-primary-color) !important;
    color: white !important;
  }

  /* Selected date in OTHER months (active date) */
  .mat-calendar-body-active .mat-calendar-body-cell-content {
    background-color: var(--customizable-primary-color) !important;
    color: white !important;
  }

  span.mat-calendar-body-cell-content.mat-focus-indicator.mat-calendar-body-selected {
    background-color: var(--customizable-primary-color) !important;
    color: white !important;
  }

  /* Hover state */
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
    .mat-calendar-body-cell-content {
    background-color: var(--customizable-primary-color) !important;
    color: white !important;
  }

  .mat-calendar-body-cell-content:focus::after {
    display: none !important;
  }
}

.contry-phone-prefix {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between !important;

  padding: 0px !important;

  .mdc-list-item__primary-text {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    width: 100% !important;
    justify-content: space-between !important;
    padding: 8px !important;
  }

  mat-pseudo-checkbox {
    display: none !important;
  }
}

.country-flag1 {
  border: 1px solid lightgray;
  width: 26px !important;
  max-width: 26px;
  height: 18px !important;
  max-height: 18px;
  object-fit: contain;
}

.custom-component-theme {
  .mat-mdc-slide-toggle
    .mdc-switch.mdc-switch--selected:enabled
    .mdc-switch__icon {
    display: none;
  }

  .mat-mdc-slide-toggle
    .mdc-switch.mdc-switch--unselected:enabled
    .mdc-switch__icon {
    display: none;
  }
}

.custom-component-theme .mat-mdc-select-value {
  color: #7a7a7a;
}

.custom-component-theme .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #eef5d0 !important;
  --mdc-switch-selected-handle-color: #cdd620 !important;
  --mdc-switch-selected-hover-state-layer-color: #cdd620;
  --mdc-switch-selected-pressed-state-layer-color: #cdd620;
  --mdc-switch-selected-focus-handle-color: #cdd620;
  --mdc-switch-selected-hover-handle-color: #cdd620;
  --mdc-switch-selected-pressed-handle-color: #cdd620;
  --mdc-switch-selected-focus-track-color: #eef5d0;
  --mdc-switch-selected-hover-track-color: #eef5d0;
  --mdc-switch-selected-pressed-track-color: #eef5d0;
  --mdc-switch-selected-track-color: #eef5d0 !important;
}

.custom-component-theme
  .mdc-text-field--filled:not(.mdc-text-field--disabled):hover:not(
    .mdc-text-field--invalid
  )
  .mdc-floating-label {
  color: #7a7a7a;
}

.custom-component-theme {
  .mat-mdc-form-field:hover .mdc-text-field--filled:not(.mdc-text-field--disabled):hover:not(.mdc-text-field--invalid) 
  .mat-mdc-floating-label {
    color: var(--customizable-primary-color) !important;
    transform: translateY(-22px) scale(0.75) !important;
  }
}

.custom-component-theme .small-button {
  display: flex;
  min-width: 40px;
  max-width: 40px;
  width: 40px;

  height: 40px;
  justify-content: center;
  align-items: center;
  border: none;

  background: var(--customizable-primary-color);
  cursor: pointer;

  &:hover {
    background: var(--customizable-second-color);
  }
}

.custom-component-theme .mdc-text-field--filled:not(.mdc-text-field--disabled):hover:not(.mdc-text-field--invalid) 
  .mdc-line-ripple::before {
  border-bottom-color: var(--customizable-primary-color) !important;
}

.events-filter-input {
  mat-label,
  .mdc-floating-label>mat-label {
    color: #1b1e1f;
    text-align: center;
    font-size: 12px; // !important;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}
