@import 'colors';

.btn-basic {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 0 20px;
  border: 0;
  font-size: 14px;
  position: relative;
  cursor: pointer;
  &:disabled {
    border: 1px solid $buttonBorderDisabled;
    color: $buttonColorDisabled;
    background: $buttonBackgroundDisabled;
    cursor: unset;
  }
}
.btn-4 {
  height: 45px;
  border: 0;
  padding: 0.875rem;
  border-radius: .5rem;
  display: flex;
  align-items: center;
  gap: .5rem;
  color: var(--U-colors-Blue);
  cursor: pointer;
  background-color: unset;
  text-decoration: unset;
  &:hover {
    background-color: #DEF6FF;
    color: var(--U-colors-Blue);
    text-decoration: unset;
  }
}
.btn-5 {
  @extend .btn-basic;
  background: $buttonBackground_0;
  color: $buttonColor_1;
  border-radius: 7px;
}
.btn-8 {
  @extend .btn-basic;
  color: $buttonColor_0;
  background-color: $buttonBackground_4;
  border: 1px solid $buttonBorder_0;
  border-radius: 7px;
}
.btn-27 {
  @extend .btn-basic;
  min-width: 40px;
  padding: 0;
  color: $buttonColor_0;
  background-color: #FFFFFF;
  border-radius: 7px;
}